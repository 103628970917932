<template>
  <div class="indicator">
    <el-row type="flex"  justify="center" class="row-bg2">
      <el-col :span="18">
        <el-container>
          <el-aside width="200px">
            <h5>版本列表</h5>
            <el-menu @select="selectVersion">
              <el-menu-item v-for="item in versionData" :key="item._id" :index="item.version">{{ item.version }} | {{ item.roe }}%</el-menu-item>
            </el-menu>
          </el-aside>
          
          <el-main>
            <el-header><h2>{{ idcName }} {{ version }}</h2></el-header>
          
            <!-- 日期选择器 -->
            <el-row type="flex" justify="center">
              <el-col :span="7">
                <span>开始日期：</span>
                <el-date-picker
                  v-on:change="dateChange"
                  size="small"
                  v-model="startDate"
                  align="center"
                  type="date"
                  placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-col>
              <el-col :span="7">
                <span>结束日期：</span>
                <el-date-picker
                  size="small"
                  v-on:change="dateChange"
                  v-model="endDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-col>
            </el-row>
            <!-- 日期选择器 end -->
            
            <!-- 收益率统计图 -->
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div ref="gainChart" style="width:100%;height: 500px;"></div>
              </el-col>
            </el-row>
            <!-- 收益率统计图 end -->
            
            <!-- 简易资金曲线统计图 -->
<!--            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div ref="balanceChart" style="width:100%;height: 500px;"></div>
              </el-col>
            </el-row> -->
            <!-- 简易资金曲线统计图 end -->
            
            <!-- 复杂资金曲线统计图 -->
            <el-row type="flex" justify="center">
              <el-col :span="24">
                <div ref="balanceBtChart" style="width:100%;height: 500px;"></div>
              </el-col>
            </el-row>
            <!-- 复杂资金曲线统计图 end -->
            
            <!-- 资金曲线统计表 -->
            <el-row type="flex" justify="space-around">
              <el-col :span="6">
                <span>dynamic 回测统计</span>
                <el-table
                  :data="balanceStatis"
                  :show-header="false"
                  alien="center"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="key"
                    label="项目">
                  </el-table-column>
                  <el-table-column
                    prop="value"
                    label="数值">
                  </el-table-column>
                </el-table>
              </el-col>
              
              <el-col :span="6">
                <span>dynamic_v2 回测统计</span>
                <el-table
                  :data="balanceStatisV2"
                  :show-header="false"
                  alien="center"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="key"
                    label="项目">
                  </el-table-column>
                  <el-table-column
                    prop="value"
                    label="数值">
                  </el-table-column>
                </el-table>
              </el-col>
              
              <el-col :span="6">
                <span>dynamic_v3 回测统计</span>
                <el-table
                  :data="balanceStatisV3"
                  :show-header="false"
                  alien="center"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="key"
                    label="项目">
                  </el-table-column>
                  <el-table-column
                    prop="value"
                    label="数值">
                  </el-table-column>
                </el-table>
              </el-col>
              
              <!-- 收益率统计表 -->
              <el-col :span="6">
                <span>信号收益统计</span>
                <el-table
                  :data="tableDataStatis"
                  :show-header="false"
                  alien="center"
                  border
                  style="width: 100%">
                  <el-table-column
                    prop="key"
                    label="项目">
                  </el-table-column>
                  <el-table-column
                    prop="value"
                    label="数值">
                  </el-table-column>
                </el-table>
              </el-col>
              <!-- 收益率统计表 end -->
            </el-row>
            <!-- 资金曲线统计表 end -->

            <!-- :cell-class-name="tableCellClassName" -->
            <el-table
              :data="tableData"
              style="width: 100%"
              :row-class-name="tableRowClassName"
              >
              <el-table-column prop="symbol" label="symbol"></el-table-column>
              <el-table-column prop="good" label="good"></el-table-column>
              <el-table-column prop="bad" label="bad"></el-table-column>
              <el-table-column prop="total" label="total"></el-table-column>
              <el-table-column prop="ratio" label="ratio"></el-table-column>
              <el-table-column prop="profit_max" label="profit_max" ></el-table-column>
              <el-table-column prop="dynamic" label="dynamic"></el-table-column>
              <el-table-column prop="dynamic_v2" label="dynamic_v2"></el-table-column>
              <el-table-column prop="dynamic_v3" label="dynamic_v3"></el-table-column>
              <el-table-column prop="options" label="options"></el-table-column>
            </el-table>    
          </el-main>
        
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<style>
  .el-table .warning-row {
    background: oldlace;
  }
  
  .el-table .danger-row {
    background: #ffeced;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>

<script>
  import moment from 'moment'
  
  export default {
    methods: {
      // 表格背景
      tableRowClassName({row}) {
        if (row.dynamic > 0) {
          return 'success-row'
        } else if (row.dynamic < 0) {
          return 'danger-row'
        }else {
          return ''
        }
      },
      
      // tableCellClassName({row, column, rowIndex, columnIndex}) {
      //   // console.log('is', row, column, rowIndex, columnIndex)
      // },
      
      // 点击侧边栏
      selectVersion(key) {
        this.version = key
        this.httpGetIdcStatis()
        this.httpBalanceCharts()
      },
      
      // 请求指标分析数据
      httpGetIdcStatis() {
        this.$http
          .get('/idc/statis', {
            params: {
              name: this.idcName,
              version: this.version,
              start_date: this.startDate,
              end_date: this.endDate,
            }
          })
          .then(response => {
            this.tableData = response.data.data.data
            this.tableDataStatis = response.data.data.statis
            this.gainData = response.data.data.charts
            // console.log(response)
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      
      // 请求资金曲线
      httpBalanceCharts() {
        this.$http
          .get('/idc/balance_charts', {
            params: {
              name: this.idcName,
              version: this.version,
              start_date: this.startDate,
              end_date: this.endDate,
            }
          })
          .then(response => {
            this.balanceBtData = {
              'xData': response.data.data.dynamic.charts.xData,
              'y1Data': response.data.data.dynamic.charts.yData,
              'y2Data': response.data.data.dynamicV2.charts.yData,
              'y3Data': response.data.data.dynamicV3.charts.yData,
            }
            console.log(response.data.data.dynamic.statis)
            this.balanceStatis = response.data.data.dynamic.statis
            this.balanceStatisV2 = response.data.data.dynamicV2.statis
            this.balanceStatisV3 = response.data.data.dynamicV3.statis
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      
      // 请求指标版本列表
      httpGetIdcList() {
        this.$http
          .get('/idc/list?name=' + this.idcName)
          .then(response => {
            this.versionData = response.data.data
            // console.log(response)
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      
      // 日期选择器改变
      dateChange() {
        if (this.startDate && this.endDate) {
          this.httpGetIdcStatis()
          this.httpBalanceCharts()
        }
      },
      
      // 初始化图表
      initCharts() {
        const gainChart = this.$refs.gainChart
        this.gainChart = this.$echarts.init(gainChart);
        
        // const balanceChart = this.$refs.balanceChart
        // this.balanceChart = this.$echarts.init(balanceChart);
        
        const balanceBtChart = this.$refs.balanceBtChart
        this.balanceBtChart = this.$echarts.init(balanceBtChart);
      },
      
      // gain 图表刷新
      setGainChart(data) {
        setTimeout(() => {
          this.gainChart.setOption({
            title: {
              text: '收益统计',
              x: 'center',
              y: 'top',
              textAlign: 'center',
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: data.xData,
            },
            yAxis: {
                type: 'value'
            },
            legend: {
              orient: 'vertical',
              right: '10%',
              data: ['dynamic', 'dynamic_v2', 'dynamic_v3']
            },
            series: [
              {
                name: 'dynamic',
                data: data.yData,
                type: 'line',
                smooth: true
              }, 
              {
                name: 'dynamic_v2',
                data: data.y2Data,
                type: 'line',
                smooth: true
              },
              {
                name: 'dynamic_v3',
                data: data.y3Data,
                type: 'line',
                smooth: true
              },
            ]
          })
        }, 100)
      },
      
      // balance 图表刷新
      // setBalanceChart(data) {
      //   setTimeout(() => {
      //     this.balanceChart.setOption({
      //       title: {
      //         text: '简易资金曲线',
      //         subtext: '初始资金: 10000',
      //         x: 'center',
      //         y: 'top',
      //         textAlign: 'center',
      //       },
      //       tooltip: {},
      //       xAxis: {
      //           type: 'category',
      //           data: data.xData,
      //       },
      //       yAxis: {
      //           type: 'value'
      //       },
      //       series: [{
      //           data: data.yData,
      //           type: 'line',
      //           smooth: true,
      //           areaStyle: {
      //             // color: rgba()
      //           }
      //       }]
      //     })
      //   }, 100)
      // },
      
      setBalanceBtChart(data) {
        setTimeout(() => {
          this.balanceBtChart.setOption({
            title: {
              text: '复杂资金曲线',
              subtext: '初始资金: 10000',
              x: 'center',
              y: 'top',
              textAlign: 'left',
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: data.xData,
            },
            yAxis: {
                type: 'value'
            },
            legend: {
              orient: 'vertical',
              right: '10%',
              data: ['dynamic', 'dynamic_v2', 'dynamic_v3']
            },
            series: [
              {
                name: 'dynamic',
                data: data.y1Data,
                type: 'line',
                smooth: true,
                areaStyle: {
                  color: 'rgba(132, 166, 229, 0.3)'
                }
              },
              {
                name: 'dynamic_v2',
                data: data.y2Data,
                type: 'line',
                smooth: true,
                areaStyle: {
                  color: 'rgba(193, 232, 154, 0.3)'
                }
              },
              {
                name: 'dynamic_v3',
                data: data.y3Data,
                type: 'line',
                smooth: true,
                // areaStyle: {
                //   color: 'rgba(193, 232, 154, 0.3)'
                // }
              },
            ]
          })
        }, 100)
      }
    },
    
    data() {
      return {
        tableData: [],
        tableDataStatis: [],
        balanceStatis: [],
        balanceStatisV2: [],
        balanceStatisV3: [],
        versionData: [],
        idcName: '',
        version: '150min',
        gainData: {},
        balanceData: {},
        balanceBtData: {},
        
        // 日期选择器 快捷选项
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },{
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }, {
            text: '一个月前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          },
          ]
        },
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        
      }
    },
    
    watch: {
      gainData(val) {
        this.setGainChart(val)
      },
      // balanceData(val) {
      //   this.setBalanceChart(val)
      // },
      balanceBtData(val) {
        this.setBalanceBtChart(val)
      },
      
    },
    
    mounted() {
      this.initCharts();
          
      this.idcName = this.$route.params.name
      this.httpGetIdcStatis(this.version)
      this.httpGetIdcList()
      this.httpBalanceCharts()

    }
  }
</script>